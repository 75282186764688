var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { unstable_ClassNameGenerator } from "@mui/material/utils";
import { memo } from "react";
import { darkTheme } from "../../stores";
// call this function at the root of the application
unstable_ClassNameGenerator.configure(function (componentName) {
    return componentName.replace("Mui", "");
});
export var AppTheme = memo(function (_a) {
    var children = _a.children;
    var theme = responsiveFontSizes(createTheme(darkTheme));
    return (_jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(CssBaseline, {}, void 0), children] }), void 0));
});
