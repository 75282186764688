var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AppBar, Container, Typography } from "@mui/material";
import { memo } from "react";
export var Footer = memo(function () {
    var date = new Date();
    var copyright = "Copyright ".concat(date.getFullYear(), " Eighty Two. All rights reserved.");
    return (_jsx(AppBar, __assign({ component: "footer", position: "fixed", color: "transparent", sx: { top: "auto", bottom: 0, mb: 2 } }, { children: _jsx(Container, { children: _jsx(Typography, __assign({ variant: "caption", color: "text.secondary" }, { children: copyright }), void 0) }, void 0) }), void 0));
});
