var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Stack } from "@mui/material";
import { memo } from "react";
import { FadeIn } from "../FadeIn";
import { Footer } from "../Footer";
import { Links } from "../Links";
import { Logo } from "../Logo";
export var AppContent = memo(function () {
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }, { children: _jsxs(Stack, __assign({ direction: "column", justifyContent: "center", alignItems: "center", spacing: 5 }, { children: [_jsx(FadeIn, { children: _jsx(Logo, {}, void 0) }, void 0), _jsx(FadeIn, __assign({ delay: 1000 }, { children: _jsx(Links, {}, void 0) }), void 0)] }), void 0) }), void 0), _jsx(FadeIn, __assign({ delay: 1500 }, { children: _jsx(Footer, {}, void 0) }), void 0)] }, void 0));
});
