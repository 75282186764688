var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Link } from "@mui/material";
import { memo } from "react";
export var Links = memo(function () {
    return (_jsxs(Stack, __assign({ direction: { xs: "column", sm: "row" }, justifyContent: "center", alignItems: "center", spacing: 5 }, { children: [_jsx(Link, __assign({ href: "mailto:ventures@eightytwo.com" }, { children: "Ventures" }), void 0), _jsx(Link, __assign({ href: "mailto:realestate@eightytwo.com" }, { children: "Real Estate" }), void 0), _jsx(Link, __assign({ href: "mailto:investments@eightytwo.com" }, { children: "Investments" }), void 0)] }), void 0));
});
