export var darkTheme = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "::selection": {
                    color: "#040405",
                    background: "#FFBF00",
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "hover",
                rel: "noreferrer",
            },
            styleOverrides: {
                root: {
                    textTransform: "uppercase",
                    textUnderlineOffset: 6,
                },
                underlineHover: {
                    "&:hover": {
                        textDecorationThickness: 2,
                    },
                },
            },
        },
    },
    palette: {
        background: {
            default: "#040405",
            paper: "#31343A",
        },
        mode: "dark",
        primary: {
            main: "#AE8639",
        },
        secondary: {
            main: "#FFBF00",
        },
        text: {
            primary: "#EFEFEF",
            secondary: "#676D7A",
        },
    },
    typography: {
        h1: {
            fontFamily: '"Noto Serif","Helvetica","Arial",sans-serif',
        },
        h2: {
            fontFamily: '"Noto Serif","Helvetica","Arial",sans-serif',
        },
        h3: {
            fontFamily: '"Noto Serif","Helvetica","Arial",sans-serif',
        },
        h4: {
            fontFamily: '"Noto Serif","Helvetica","Arial",sans-serif',
        },
        h5: {
            fontFamily: '"Noto Serif","Helvetica","Arial",sans-serif',
        },
        h6: {
            fontFamily: '"Noto Serif","Helvetica","Arial",sans-serif',
            fontSize: "1rem",
        },
        fontFamily: '"Manrope","Helvetica","Arial",sans-serif',
        fontWeightRegular: 500,
    },
};
