import debug from "debug";
var logger = debug("eightytwo:stores");
export var registerServiceWorker = function () {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
            navigator.serviceWorker.register("/service-worker.js").then(function (registration) {
                logger("SW registered: ", registration);
            }).catch(function (registrationError) {
                logger("SW registration failed: ", registrationError);
            });
        });
    }
};
